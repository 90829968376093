import { LOCALSTORAGE_PASSWORD_KEY } from "../config";
import { useState } from "react";
import HomePage from "./HomePage";
import Login from "./Login";
import Impressum from "./Impressum";

const initFromLocalStorage = (key: string) => {
  const storageValue = localStorage.getItem(key);
  if (storageValue == null) {
    return "";
  }
  return storageValue;
};

const SinglePage = () => {
  const [password, setPassword] = useState(
    initFromLocalStorage(LOCALSTORAGE_PASSWORD_KEY)
  );
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showImpressum, setShowImpressum] = useState(false);

  return (
    <>
      {isLoggedIn && !showImpressum && <HomePage setShowImpressum={setShowImpressum}/>}
      {!isLoggedIn && !showImpressum && (
        <Login
          password={password}
          setPassword={setPassword}
          setIsLoggedIn={setIsLoggedIn}
          setShowImpressum={setShowImpressum}
        />
      )}
      {showImpressum && (<Impressum isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setShowImpressum={setShowImpressum} setPassword={setPassword} />)}
    </>
  );
};

export default SinglePage;
