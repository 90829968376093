import { Box, IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { LOCALSTORAGE_PASSWORD_KEY } from "../config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface InterfaceProps {
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setShowImpressum: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  isLoggedIn: boolean;
}

const Impressum = ({
  setPassword,
  setShowImpressum,
  setIsLoggedIn,
  isLoggedIn,
}: InterfaceProps) => {
  const logout = () => {
    localStorage.removeItem(LOCALSTORAGE_PASSWORD_KEY);
    setShowImpressum(false);
    setPassword("");
    setIsLoggedIn(false);
  };

  const goBack = () => {
    setShowImpressum(false);
  };

  return (
    <Box sx={{ padding: "5%" }}>
      <IconButton onClick={goBack}>
        <ArrowBackIcon sx={{ color: "grey" }}></ArrowBackIcon>
      </IconButton>
      <h1>Impressum</h1>
      <p>
        Name: Marian Zoll
        <br />
        Email: Marian.Zoll@web.de
        <br />
      </p>
      <p>
        Responsible for the content according to § 55 Abs. 2 RStV: Marian Zoll
        <br />
      </p>

      {isLoggedIn && (
        <IconButton onClick={logout}>
          <LogoutIcon sx={{ color: "grey" }}></LogoutIcon>
        </IconButton>
      )}
    </Box>
  );
};

export default Impressum;
