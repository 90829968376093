import "./NamePageBreak.css"
import { Box, Typography } from "@mui/material";
import heart from "../../assets/blackHeart.svg";
import siegel from "../../assets/siegelring_logo.png";

export const NamePageBreak = () => {
  return (
    <Box
      sx={{
        minHeight: "10%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%"
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <Box sx={{width: "45%", display: "flex", justifyContent: "flex-end"}}>
        <Typography variant="h1" sx={{marginRight: "10px"}}>Franzi</Typography>
        </Box>
        <img src={heart} color="black" width={"20px"} height={"20px"} alt="Logo" /> 
        <Box sx={{width: "45%" }}>
        <Typography variant="h1" sx={{marginLeft: "10px"}}>Marian</Typography>
        </Box>
       
        
      </Box>
    </Box>
  );
};