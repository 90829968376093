import { useEffect, useRef } from "react";
import {
  Box,
  CardContent,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NamePageBreak } from "../components/NamePageBreak";
import saveTheDateImage from "./../assets/homepage.jpg"; //savethedate-v2.jpg
import dropboxCodeImage from "./../assets/dropbox_dateianfrage_qrcode.svg";
import DetailCard from "../components/DetailCard";
import { Footer } from "../components/Footer";
import Trauzeuge from "../components/DetailCard/Trauzeuge";
import franziMarianGif from "./../assets/franziMariGif.mp4";
import hochzeisvideo from "./../assets/videos/hochzeitsvideo.mp4";
import glassesReel from "./../assets/videos/glasses_real.mp4";
import qrCodeFotogalerie from "./../assets/qrcode_fotogalerie.png"

import { FONT_WEIGHT_TEXT } from "../config";

interface HomePageProps {
  setShowImpressum: React.Dispatch<React.SetStateAction<boolean>>;
}

const _MARGIN_TOP_TEXT = "10px";
const _TS_TO_REMOVE_MUSIC_DETAIL_CARD = new Date(
  "2024-08-17T00:00:00Z"
).getTime();

const HomePage = ({ setShowImpressum }: HomePageProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const videoRef = useRef<HTMLVideoElement>(null);
  const glassesReelRef = useRef<HTMLVideoElement>(null);
  const hochzeitsvideoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {}, []);

  useEffect(() => {
    const scrollToBottom = () => {
      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
    };

    scrollToBottom();

    const scrollBackToTop = setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 500);

    if (videoRef.current) {
      videoRef.current.playbackRate = 0.35; // Setzt die Wiedergabegeschwindigkeit auf 50%
    }

    return () => {
      clearTimeout(scrollBackToTop);
    };
  });

  return (
    <>
      <Box sx={{ textAlign: "justify" }}>
        <Box
          id="background-image"
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: "100vw",
            height: "90vh",
            "@media (min-width: 961px)": {
              height: "100vh",
            },
            overflow: "hidden",
            backgroundSize: "cover",
            backgroundPosition: "10% 0%",
            "@media (max-width: 600px)": {
              backgroundPosition: "45% 0%",
            },
            backgroundImage: `linear-gradient(to bottom, rgba(40, 40, 40, 0.4), rgba(114, 114, 114, 0.52)), url(${saveTheDateImage})`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignSelf: "center",
              justifySelf: "center",
              marginTop: "1%",
              flexDirection: "column",
              color: "white",
              textShadow: "1px 1px 4px rgba(0, 0, 0, 0.5)",
            }}
          >
            <Box
              sx={{
                fontFamily: "SignatureCollection",
                "@media (max-width: 600px)": {
                  fontSize: "6em",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "8em",
                },
                "@media (min-width: 961px)": {
                  fontSize: "10em",
                },
              }}
            >
              Dankeschön!
            </Box>
          </Box>
          <Box
            id="date"
            sx={{
              position: "absolute",
              bottom: "20px",
              alignSelf: "center",
              justifySelf: "center",
              marginTop: "1%",
              flexDirection: "column",
              color: "white",
              textShadow: "1px 1px 4px rgba(0, 0, 0, 0.5)",
              marginBottom: "2.5%", // Set a margin of 2.5% to the bottom
              "@media (max-width: 600px)": {
                fontSize: "2em",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "2.5em",
              },
              "@media (min-width: 961px)": {
                fontSize: "3.5em",
              },
            }}
          >
            17. August 2024
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <NamePageBreak />
          <Box
            sx={{
              width: "15%",
              marginTop: "10px",
              marginBottom: "10px",
              backgroundColor: "black",
            }}
          >
            <Divider />
          </Box>
          <Box
            sx={{
              marginBottom: "20px",
            }}
          >
            {/* <Countdown /> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "90%",
            }}
          >
            <DetailCard
              title="EIN GIGANTISCHES DANKESCHÖN"
              showInfo={false}
              infoText=""
              isInitiallyFlipped={true}
            >
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                  <Typography
                      color="textSecondary"
                      style={{ marginTop: "10px", textAlign: "center", marginBottom: "10px" }}
                      sx = {{
                          "@media (max-width: 600px)": {
                              fontSize: "1.25em"
                          },
                          "@media (min-width: 601px) and (max-width: 960px)": {
                              fontSize: "1.375em"
                          },
                          "@media (min-width: 960px)": {
                              fontSize: "1.5em"
                          },
                      }}
                  >
                      Vielen, vielen Dank, dass Ihr so ausgiebig mit uns gefeiert habt! Wir sind überglücklich und dankbar dafür, so wunderbare Menschen um uns zu haben und hätten uns unsere Hochzeit nicht schöner erträumen können.
                  </Typography>
                <video
                  ref={videoRef} // Referenz auf das Video-Element
                  src={franziMarianGif} // Ersetze dies durch den Pfad zu deinem MP4-Video
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{ width: "100%", height: "auto", maxWidth: "300px", marginBottom: "10px" }}
                >
                  Dein Browser unterstützt dieses Video-Format nicht.
                </video>
                  <Box style={{fontFamily: "SignatureCollection", fontSize: "40px"}}>
                      Eure Zolls
                  </Box>
              </CardContent>
            </DetailCard>
              <DetailCard title="LASST DIE KORKEN KNALLEN, TON AN UND LOS GEHT'S!" showInfo={false} infoText="">
                  <CardContent
                      style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                      }}
                  >
                      <video
                          ref={glassesReelRef}
                          src={glassesReel}
                          controls
                          controlsList="nodownload"
                          loop
                          playsInline
                          style={{width: "100%", height: "auto", maxWidth: "300px"}}
                      >
                          Dein Browser unterstützt dieses Video-Format nicht.
                      </video>
                      <Box>
                          <Typography sx = {{
                              "@media (max-width: 600px)": {
                                  fontSize: "1.25em"
                              },
                              "@media (min-width: 601px) and (max-width: 960px)": {
                                  fontSize: "1.375em"
                              },
                              "@media (min-width: 960px)": {
                                  fontSize: "1.5em"
                              },
                          }}
                                      style={{ marginTop: "30px", marginBottom: "10px" }}>
                              Unser Hochzeitsvideo:
                          </Typography>
                      </Box>
                      <video
                          ref={hochzeitsvideoRef}
                          src={hochzeisvideo}
                          controls
                          controlsList="nodownload"
                          loop
                          playsInline
                          style={{width: "100%", height: "auto", maxWidth: "800px"}}
                      >
                          Dein Browser unterstützt dieses Video-Format nicht.
                      </video>
                  </CardContent>
              </DetailCard>
              <DetailCard title="ZUR FOTOGALERIE" showInfo={false} infoText="">
                  <CardContent
                      sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                      }}
                  >
                      <Box
                          sx={{
                              display: "flex",
                              flexDirection: "column",
                          }}
                      >
                          <Typography sx = {{
                              "@media (max-width: 600px)": {
                                  fontSize: "1.25em"
                              },
                              "@media (min-width: 601px) and (max-width: 960px)": {
                                  fontSize: "1.375em"
                              },
                              "@media (min-width: 960px)": {
                                  fontSize: "1.5em"
                              },
                          }}>
                              Hier geht es zu unserer Fotogalerie. Viel Spaß beim Anschauen:
                          </Typography>
                          {/*<Typography>*/}
                          {/*    Für alle spontanen und geplanten Schnappschüsse, die Ihr*/}
                          {/*    während der Hochzeit gemacht habt, würden wir uns freuen,*/}
                          {/*    wenn Ihr diese hochladen könntet. Klickt hierzu einfach auf*/}
                          {/*    den Link oder scanned den QR-Code. VIELEN DANK 🙂*/}
                          {/*</Typography>*/}
                          <Box
                              sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: _MARGIN_TOP_TEXT,
                              }}
                          >
                              <Typography
                                  sx={{
                                      marginRight: "16px", // Space before the divider
                                      "@media (max-width: 600px)": {
                                          fontSize: "1.25em"
                                      },
                                          "@media (min-width: 601px) and (max-width: 960px)": {
                                          fontSize: "1.375em"
                                      },
                                          "@media (min-width: 960px)": {
                                          fontSize: "1.5em"
                                      },
                                  }}
                              >
                                  <a
                                      style={{ color: "inherit" }}
                                      target="_blank"
                                      rel="noreferrer"
                                      // https://www.dropbox.com/request/J7IhqNVJ8Cz6YY6b2R0T
                                      href="https://danielpreuheimatliebefotografie.pic-time.com/RHkyX3z9cok3s"
                                  >
                                      Link zur Fotogalerie
                                  </a>
                              </Typography>
                              <Divider
                                  orientation="vertical"
                                  flexItem
                                  sx={{
                                      height: "50px", // Custom height for the divider
                                      marginX: "16px", // Space around the divider
                                      alignSelf: "center", // Center the divider vertically
                                  }}
                              />
                              <Box
                                  component="img"
                                  alt="QR Code"
                                  src={qrCodeFotogalerie}
                                  sx={{
                                      width: "auto",
                                      maxHeight: "100px",
                                      filter:
                                          "invert(55%) sepia(10%) saturate(250%) hue-rotate(15deg) brightness(90%) contrast(85%)",
                                  }}
                              />
                          </Box>
                      {/*    <Typography sx={{ marginTop: _MARGIN_TOP_TEXT }}>*/}
                      {/*        Um Euch schonmal einen kleinen Vorgeschmack zu geben, findet*/}
                      {/*        Ihr hier bereits ein paar Schnappschüsse der letzten Jahre:*/}
                      {/*    </Typography>*/}
                      {/*</Box>*/}
                      {/*<Box*/}
                      {/*    sx={{*/}
                      {/*        marginTop: _MARGIN_TOP_TEXT,*/}
                      {/*        display: "flex",*/}
                      {/*        alignItems: "center", // Center items horizontally*/}
                      {/*        justifyContent: "center",*/}
                      {/*    }}*/}
                      {/*>*/}
                      {/*    <ImageCarousel></ImageCarousel>*/}
                      </Box>
                  </CardContent>
              </DetailCard>
            <DetailCard
              title="GEFUNDEN ODER VERLOREN?"
              showInfo={false}
              infoText=""
              isInitiallyFlipped={false}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography>
                  Falls Ihr an unserer Hochzeit etwas verloren habt oder Euch
                  am nächsten Tag aufgefallen sein sollte, dass Ihr mit einem
                  zusätzlichen Paar Socken (das Euch nicht gefallen hat) nach Hause gekommen seid, dann meldet
                  Euch gerne bei uns.
                </Typography>
                <Typography sx={{ marginTop: _MARGIN_TOP_TEXT }}>
                  Aktuell wären auf der Liste:
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    <li>- Sonnenbrille (wanted)</li>
                  </ul>
                </Typography>
                <Typography>Vielen Dank an Euch alle!</Typography>
              </Box>
            </DetailCard>
              <DetailCard title="KONTAKTE" showInfo={false} infoText="">
                  <Box>
                      <Typography>
                          Ihr dürft Euch natürlich jederzeit gerne an uns wenden
                          (Franzi:{" "}
                          <a
                              href={"tel:017654700787"}
                              style={{ color: "inherit" }}
                              target="_blank"
                              rel="noreferrer"
                          >
                              0176 54700787
                          </a>
                          , Marian:{" "}
                          <a
                              href={"tel:015209033846"}
                              style={{ color: "inherit" }}
                              target="_blank"
                              rel="noreferrer"
                          >
                              0152 09033846
                          </a>
                          ).
                      </Typography>
                      <Typography sx={{ marginTop: _MARGIN_TOP_TEXT }}>
                          Vielleicht können Euch aber auch unsere lieben Trauzeugen
                          weiterhelfen:
                      </Typography>
                      <Trauzeuge
                          name="Christina Österlein"
                          avatar={true}
                          avatarImage="" //{avatarChristinaOesterlein}
                          avatarName="C"
                          funfact=""
                          phone="0176 80226074"
                          mail=""
                      ></Trauzeuge>
                      <Trauzeuge
                          name="Maria Schöpp"
                          avatar={true}
                          avatarImage="" //{avatarMariaSchoepp}
                          avatarName="M"
                          funfact="" //wartet seit Jahren vergebens auf einen Brief aus Hogwarts.
                          phone="0176 31265367"
                          mail=""
                      ></Trauzeuge>
                      <Trauzeuge
                          name="Florian Bentele"
                          avatar={true}
                          avatarImage="" //{avatarFlorianBentele}
                          avatarName="F"
                          funfact=""
                          phone="0151 22325547"
                          mail=""
                      ></Trauzeuge>
                      <Trauzeuge
                          name="Daniel Jäck"
                          avatar={true}
                          avatarImage="" //{avatarDanielJaeck}
                          avatarName="D"
                          funfact=""
                          phone="0151 58011194"
                          mail=""
                      ></Trauzeuge>
                  </Box>
              </DetailCard>
          </Box>
        </Box>
        <Footer setShowImpressum={setShowImpressum} />
      </Box>
    </>
  );
};

export default HomePage;
