import { Typography, Box, Avatar } from "@mui/material";

interface TrauzeugeProps {
  name: string;
  avatar: boolean;
  avatarImage: string;
  avatarName: string;
  funfact: string;
  phone: string;
  mail: string;
}

const Trauzeuge: React.FC<TrauzeugeProps> = ({
  name,
  avatar,
  avatarImage,
  avatarName,
  funfact,
  phone,
  mail,
}) => {
  return (
    <Box
      sx={{
        margin: "10px",
        marginTop: "20px",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      {avatar && avatarImage.length > 0 && (
        <Avatar
          alt={name}
          src={avatarImage}
          sx={{ alignSelf: "center", marginRight: "10px" }}
        />
      )}
      {avatar && avatarName.length > 0 && (
        <Avatar
          alt={name}
          sx={{
            alignSelf: "center",
            marginRight: "10px",
            fontFamily: "SignatureCollection",
            fontWeight: 700,
          }}
        >
          {avatarName}
        </Avatar>
      )}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h3">{name}</Typography>
        {funfact.length > 0 && <Typography>... {funfact}</Typography>}
        {phone.length > 0 && (
          <Box sx={{display: "flex", flexDirection: "row"}}>
            <Typography sx={{marginRight: "5px"}}>Tel.:</Typography>
            <a
              href={"tel:" + phone}
              style={{ color: "inherit" }}
              target="_blank"
              rel="noreferrer"
            >
              {phone}
            </a>
          </Box>
        )}
        {mail.length > 0 && (
          <a
            href={"mailto:" + mail}
            style={{ color: "inherit" }}
            target="_blank"
            rel="noreferrer"
          >
            {mail}
          </a>
        )}
      </Box>
    </Box>
  );
};

export default Trauzeuge;
