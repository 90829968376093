import { Box } from "@mui/material";

import siegel from "../../assets/siegelring_logo.png";

const LoadingScreen = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          width: "107px",
          height: "108px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          animation: "increaseSize 3s infinite alternate",
          "@keyframes increaseSize": {
            "0%": {
              transform: "scale(1)",
            },
            "100%": {
              transform: "scale(1.5)",
            },
          },
          fontFamily: "SignatureCollection",
          "@font-face": {
            fontFamily: "SignatureCollection",
            src: "url(./../../assets/fonts/SignatureCollection.otf) format('truetype')",
          },
        }}
      >
        <img src={siegel} color="black" width={"107px"} alt="Siegelring" />
      </Box>
    </Box>
  );
};

export default LoadingScreen;
