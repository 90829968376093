import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SinglePage from "./pages/SinglePage";

const theme = createTheme({
  typography: {
    fontFamily: "AlegriaSans",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontSize: 28,
      fontWeight: 550
    },
    h2: {
      fontSize: 22
    },
    h3: {
      fontSize: 16,
      fontWeight: 600
    },
    body2: {
      fontSize: 15,
    },
  },
  palette: {
    primary: {
      main: "#656565",
      contrastText: "#000",
    },
    secondary: {
      main: "#2B2B2B",
    },
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path={`/`} element={<SinglePage />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
