import { Box, Button } from "@mui/material";

interface FooterProps {
  setShowImpressum: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Footer = ({ setShowImpressum }: FooterProps) => {
  return (
    <Box
      sx={{
        minHeight: "10%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2%",
        marginBottom: "2%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          className="inherit-color"
          onClick={() => setShowImpressum(true)}
          sx={{color: "grey"}}
        >
          Impressum
        </Button>
      </Box>
    </Box>
  );
};
