import {
  Card,
  Typography,
  CardMedia,
  CardActions,
  Box,
  IconButton,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

interface CustomCardProps {
  title: string;
  showInfo: boolean;
  infoText: string;
  boldHeader?: boolean;
  isInitiallyFlipped?: boolean;
  children: React.ReactNode;
}

const DetailCard: React.FC<CustomCardProps> = ({
  title,
  showInfo,
  infoText,
  boldHeader = false,
  isInitiallyFlipped = false,
  children,
}) => {
  const [isFlipped, setIsFlipped] = useState(isInitiallyFlipped);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: "100%",
        boxShadow: "1px 1px 4px rgba(150, 150, 150, 0.5)",
        margin: "10px",
        "@media (max-width: 600px)": {
          maxWidth: "100%",
        },
      }}
    >
      {!isFlipped && (
        <CardMedia
          onClick={handleCardClick}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: 120,
            backgroundImage:
              "linear-gradient(to bottom, var(--color-gradient), #FFF)",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            paddingLeft: "20px",
            paddingRight: "20px"
          }}
          title={title}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: boldHeader ? "bold !important" : "normal !important",
              "@media (max-width: 600px)": {
                fontSize: "1.25em"
              },
                "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "1.375em"
              },
                "@media (min-width: 960px)": {
                fontSize: "1.5em"
              },
            }}
          >
            {title}
          </Typography>
          {showInfo && (
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                backgroundColor: "#FAF0E6",
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                {infoText}
              </Typography>{" "}
            </Box>
          )}
        </CardMedia>
      )}
      {isFlipped && (
        <CardActions>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              padding: "20px",
              width: "calc(100% - 40px)",
            }}
          >
            <Box
              onClick={handleCardClick}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
              }}
            >
              <KeyboardArrowLeft
                sx={{ marginRight: "10px", color: "grey", marginLeft: -0.9 }}
                onClick={handleCardClick}
              />
              <Typography variant="h2" sx={{fontWeight: "bold", textAlign: "left"}}>{title}</Typography>
            </Box>
            <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
              <Divider />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {children}
            </Box>
          </Box>
        </CardActions>
      )}
    </Card>
  );
};

export default DetailCard;
